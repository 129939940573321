// import React from 'react'
import React, { useState } from 'react';
import about from "../images/about.jpg";
import lawyerImage from "../images/law.jpg";
import './counseling.css'

const Counseling = () => {
    const [activeButton, setActiveButton] = useState('button1'); // State to track active button

    const handleButtonClick = (buttonName) => {
      setActiveButton(buttonName); // Update active button state
    };
  return (

    <div>
      <br></br>
        <div className="app">
          <div className="image-container">
           <img
          className="background-image"
          src={about}
          alt="About"
        />
        <div className="content-container">
          {/* <div className="headings-container">
            <h2 className="heading">HOW WE HELP</h2>
            <h1 className="sub-heading">Employees</h1>
          </div> */}
          <div className="button-container">
              <h2 className='heading2'>HOW WE HELP</h2>
              <p className="Paragraph1">We represent executives and employees in all types of employment disputes and advisory matters.Some of our key practice areas are:</p>
              <p className="Paragraph1">Some of our key practice areas are:</p>
            {/* Buttons to toggle content */}
            <button className={activeButton === 'button1' ? 'active' : ''} onClick={() => handleButtonClick('button1')}>
             Startups
            </button>
            <button className={activeButton === 'button2' ? 'active' : ''} onClick={() => handleButtonClick('button2')}>
            Counseling            </button>
            <button className={activeButton === 'button3' ? 'active' : ''} onClick={() => handleButtonClick('button3')}>
            Disputes
            </button>
            <button className={activeButton === 'button4' ? 'active' : ''} onClick={() => handleButtonClick('button4')}>
            Competition
            </button>
            <hr />
          </div>
          {/* Content based on active button */}
          <div className="text-container">
            {activeButton === 'button1' && (
              <>
             <div id="buttonnn1">
  <img
    id="side-image"
    src={lawyerImage}
    alt="Lawyer Image"
  />
  <div id="content">
    <h2 id="heading2">Emerging Companies and Startups</h2>
    <p id="heading-p1">COMPLIANCE</p>
    <p id="Paragraph1">We advise startups on all types of day-to-day employment matters, such as hiring, internal complaints, disability accommodations and terminations. With our big law experience, we provide the experience and expertise of a large firm, with more personalized service, efficiency and flexibility.</p>
    <p id="heading-p2">WORKPLACE CULTURE</p>
    <p id="Paragraph2">Developing a strong culture of respect, inclusion and speaking up is critical for emerging companies. We have helped dozens of startups, technology and venture-backed companies implement policies, training and management styles that reflect company-specific values and promote appropriate workplace behaviors.</p>
  </div>
</div>

              </>
            )}
            {activeButton === 'button2' && (
              <>
             <div id="buttonnn2">
  <img
    id="side-image"
    src={lawyerImage}
    alt="Lawyer Image"
  />
  <div id="content">
    <h2 id="heading2">Counseling and Training</h2>
    <p id="heading-p1">EMPLOYMENT COUNSELING</p>
    <p id="Paragraph1">We help companies minimize the risk of litigation with pragmatic employment advice, policies and training. We advise on the full lifecycle of employment, from recruiting and on-boarding through terminations and layoffs. We update employee handbooks, policies and agreements for compliance with applicable laws and best practices.</p>
    <p id="heading-p2">HARASSMENT TRAINING</p>
    <p id="Paragraph2">New York and other states now require employers to conduct anti-sexual harassment training. We provide engaging, informative and effective training programs for executives, managers and individual contributors that fulfill these requirements. We also conduct training sessions on respect in the workplace, leadership and management skills, diversity, inclusion and other employment topics.</p>
  </div>
</div>

              </>
            )}
            {activeButton === 'button3' && (
              <>
                 <div id="buttonnn2">
  <img
    id="side-image"
    src={lawyerImage}
    alt="Lawyer Image"
  />
  <div id="content">
    <h2 id="heading2">Disputes and Investigations</h2>
    <p id="paragraph1">We are skilled litigators who have handled hundreds of employment disputes. We represent individuals in all types of employment cases, including claims of:</p>
    <p id="heading-p">WORKPLACE INVESTIGATIONS</p>
    <p id="paragraph2">How companies respond when employees report harassment, retaliation, whistleblowing and other misconduct is critical. A prompt, effective and neutral investigation of an employee complaint can stop bad behavior, improve company culture and assist in the defense of a lawsuit.</p>
    <p id="paragraph3">Often, employee complaints are too sensitive or complex to be handled internally. We conduct independent investigations for clients in high-risk situations, such as claims of sexual harassment, abusive or illegal behavior, or misconduct by high-level executives. We also investigate complaints for companies where an internal investigation could appear biased or where a potential conflict of interest exists.</p>
    <p id="paragraph4">For clients conducting their own internal investigations, we provide privileged legal advice throughout the investigative process. We also help companies develop complaint triage protocols to promote consistency across investigations and to ensure the appropriate stakeholders stay informed.</p>
  </div>
</div>

              </>
            )}
            {activeButton === 'button4' && (
              <>
              <div id="buttonnn2">
  <img
    id="side-image"
    src={lawyerImage}
    alt="Lawyer Image"
  />
  <div id="content">
    <h2 id="heading2">Disputes and Investigations</h2>
    <p id="paragraph1">We are skilled litigators who have handled hundreds of employment disputes. We represent individuals in all types of employment cases, including claims of:</p>
    <p id="heading-p">WORKPLACE INVESTIGATIONS</p>
    <p id="paragraph2">How companies respond when employees report harassment, retaliation, whistleblowing and other misconduct is critical. A prompt, effective and neutral investigation of an employee complaint can stop bad behavior, improve company culture and assist in the defense of a lawsuit.</p>
    <p id="paragraph3">Often, employee complaints are too sensitive or complex to be handled internally. We conduct independent investigations for clients in high-risk situations, such as claims of sexual harassment, abusive or illegal behavior, or misconduct by high-level executives. We also investigate complaints for companies where an internal investigation could appear biased or where a potential conflict of interest exists.</p>
    <p id="paragraph4">For clients conducting their own internal investigations, we provide privileged legal advice throughout the investigative process. We also help companies develop complaint triage protocols to promote consistency across investigations and to ensure the appropriate stakeholders stay informed.</p>
  </div>
</div>

              </>
            )}
          </div>
        </div>
      </div>
    </div>


    <br></br>

    </div>
  )
}

export default Counseling