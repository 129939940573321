// src/App.js
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Banner from "./components/banner/Banner";
import Contact from "./components/contact/Contact";
import Features from "./components/features/Features";
import Footer from "./components/footer/Footer";
import FooterBottom from "./components/footer/FooterBottom";
import Navbar from "./components/navbar/Navbar";
import EmployementAgreements from "./pages/Employementarguments/EmployementAgreements";
import Counseling from "./pages/counseling/Counseling";
import EqualPay from "./pages/enqualpay/EqualPay";
import Litigation from "./pages/litigation/Litigation";
import Sexvel from "./pages/sexvelcases/Sexvel";
import Startups from "./pages/startups/Startups";

function App() {
  return (
    <Router>
      <div className="w-full h-auto bg-bodyColor text-lightText px-4">
        <Navbar />
        <div className="max-w-screen-xl mx-auto">
          <Routes>
            <Route path="/" element={<Banner />} />
            <Route path="/features" element={<Features />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/employement-agreements" element={<EmployementAgreements />} />
            <Route path="/counseling" element={<Counseling />} />
            <Route path="/equal-pay" element={<EqualPay />} />
            <Route path="/litigation" element={<Litigation />} />
            <Route path="/sexvel" element={<Sexvel />} />
            <Route path="/startups" element={<Startups />} />
          </Routes>
          <Footer />
          <FooterBottom />
        </div>
      </div>
    </Router>
  );
}

export default App;
