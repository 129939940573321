import { MdLocalPolice } from "react-icons/md";
import { GiCrimeSceneTape } from "react-icons/gi";
import { FaMousePointer } from "react-icons/fa";
import { FaStar } from "react-icons/fa";
import { FaHourglassStart } from "react-icons/fa";


// Features Data
export const featuresData = [
  {
    id: 1,
    title: "Employment Agreements",
    des: "We represent executives and employees in all types of employment disputes and advisory matters.  Some of our key practice areas are:",
  },
  {
    id: 2,
    icon: <MdLocalPolice />,
    title: "Counseling",
    des: "We represent companies in technology, retail, real estate, financial services and other industries in all types of employment matters.  Some of our key practice areas are:",
  },
  {
    id: 3,
    icon: <GiCrimeSceneTape />,
    title: "Startups",
    des: "We represent executives and employees in all types of employment disputes and advisory matters.  Some of our key practice areas are:",
  },
  {
    id: 4,
    icon: <FaMousePointer />,
    title: " Litigation",
    des: "We represent companies in technology, retail, real estate, financial services and other industries in all types of employment matters.  Some of our key practice areas are:",
  },
  {
    id: 5,
    icon: <FaStar />,
    title: "Sexual Harassment",
    des: "We represent executives and employees in all types of employment disputes and advisory matters.  Some of our key practice areas are:",
  },
  {
    id: 6,
    icon: <FaHourglassStart />,
    title: "Equal Pay",
    des: "We represent executives and employees in all types of employment disputes and advisory matters.  Some of our key practice areas are:",
  },
];
