
import Title from '../layouts/Title';
import ContactLeft from './ContactLeft';

import React, { useState } from 'react';
import emailjs from 'emailjs-com';

const Contact = () => {
  const [username, setUsername] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [email, setEmail] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [errMsg, setErrMsg] = useState('');
  const [successMsg, setSuccessMsg] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!username || !email || !subject || !message) {
      setErrMsg('Please fill in all required fields.');
      return;
    }

    const templateParams = {
      from_name: username,
      phone_number: phoneNumber,
      reply_to: email,
      to_name: 'Your Name', 
      subject,
      message_html: message,
    };

    emailjs.send('service_gd59kqe', 'template_s6j54j5', templateParams, 'wQndGcbxAzQsVEgnY')
      .then((response) => {
        console.log('Email successfully sent!', response);
        setSuccessMsg(`Thank you ${username}, your message has been sent successfully!`);
        setErrMsg('');
        setUsername('');
        setPhoneNumber('');
        setEmail('');
        setSubject('');
        setMessage('');
      })
      .catch((error) => {
        console.error('Email sending failed:', error);
        setErrMsg('Failed to send message. Please try again later.');
        setSuccessMsg('');
      });
  };

  return (
    <section id="contact" className="w-full py-20 border-b-[1px] border-b-black">
      <div className="flex flex-col md:flex-row justify-between items-start">
        <ContactLeft />
        <div className="w-full md:w-2/3 mt-10 md:mt-0">
          <div className="flex justify-center items-center text-center mb-10">
            <h2 className="text-3xl font-bold text-gray-900">Contact Us</h2>
          </div>
          <div className="w-full">
            <form className="w-full max-w-lg mx-auto" onSubmit={handleSubmit}>
              {errMsg && (
                <p className="text-red-500 text-sm mb-2">{errMsg}</p>
              )}
              {successMsg && (
                <p className="text-green-500 text-sm mb-2">{successMsg}</p>
              )}
              <div className="flex flex-wrap -mx-3 mb-6">
                <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                  <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                    Your Name
                  </label>
                  <input
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                    type="text"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    required
                    name='user_name'
                  />
                </div>
                <div className="w-full md:w-1/2 px-3">
                  <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                    Phone Number
                  </label>
                  <input
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    type="tel"
                    value={phoneNumber}
                    name='user_Number'
                    onChange={(e) => setPhoneNumber(e.target.value)}
                  />
                </div>
              </div>
              <div className="flex flex-wrap -mx-3 mb-6">
                <div className="w-full px-3">
                  <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                    Email
                  </label>
                  <input
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                    name='user_email'
                  />
                </div>
              </div>
              <div className="flex flex-wrap -mx-3 mb-6">
                <div className="w-full px-3">
                  <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                    Subject
                  </label>
                  <input
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    type="text"
                    value={subject}
                    name='subject'
                    onChange={(e) => setSubject(e.target.value)}
                    required
                  />
                </div>
              </div>
              <div className="flex flex-wrap -mx-3 mb-6">
                <div className="w-full px-3">
                  <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                    Message
                  </label>
                  <textarea
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 h-48 resize-none"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    required
                    name='message'
                  ></textarea>
                </div>
              </div>
              <div className="flex justify-center">
                <button
                  type="submit"
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                >
                  Send Message
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;