import React from "react";
import Title from "../layouts/Title";
import Card from "./Card";
import { featuresData } from "../../data/data";
import about from './images/about.jpg';
import lawyerImage from './images/law.jpg'
import { useState } from 'react';
import './features.css'
const Features = () => {
  const [showContent1, setShowContent1] = useState(false);
  const [showContent2, setShowContent2] = useState(false);

  const toggleContent1 = () => {
    setShowContent1(!showContent1);
    if (showContent2) setShowContent2(false);
  };

  const toggleContent2 = () => {
    setShowContent2(!showContent2);
    if (showContent1) setShowContent1(false);
  };

  return (
    <section id="features" className="w-full py-20 border-b-[1px] border-b-black">
      <Title title="Features" des="What I Do" />
      <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-6 xl:gap-20">
        {featuresData.map((item) => (
          <Card item={item} key={item.id} />
        ))}
      </div>
      <br></br>

      <div className="app">
        <div className="image-container">
          <img className="background-image" src={about} alt="About" />
          <div className="content-container">
            <div className="pp">
              <div className="text-container">
                <h1 className="heading1">Kamalaka</h1>
                <p className="Paragraph">
                  Lawyers offer legal services to clients, businesses, and government agencies on legal issues and
                  disputes. Lawyers can wear multiple hats and are known for doing a range of tasks, including
                  conducting depositions, counselling clients, interrogating witnesses, drafting legal documents, and
                  performing legal research. Lawyers can focus on a wide range of legal disciplines, including criminal
                  law, constitutional law, commercial law, and intellectual property law. There is a specific lawyer in
                  every field who specializes in dealing with a particular legal issue. The law profession has been
                  emerging for decades, there have been new emerging opportunities like cyber lawyers, environmental
                  lawyers, etc. Read the complete article to know more about types of lawyers, roles, salaries and other
                  related information.
                </p>
                <p className="Paragraph">
                  Beyond courtroom appearances, lawyers play pivotal roles in drafting legal documents, conducting
                  thorough research to support their cases, and negotiating settlements to resolve conflicts amicably.
                  Upholding ethical standards and legal integrity, lawyers ensure that justice is served while
                  safeguarding the rights and interests of their clients within the framework of the law. Their
                  dedication to upholding legal principles and their commitment to ensuring fair outcomes underscore
                  their significance in upholding societal norms and individual rights.
                </p>
                <p className="Paragraph">
                  In addition to law firms, lawyers may work in corporate legal departments, providing in-house
                  counsel to businesses and organizations. In this setting, lawyers may have offices within the
                  company's headquarters or work remotely, depending on the organization's structure and policies.
                  Corporate lawyers often work closely with executives, managers, and other departments to address legal
                  issues related to corporate governance, contracts, regulatory compliance, intellectual property, and
                  litigation management. They may also collaborate with external counsel and other professionals to
                  handle specialized legal matters.
                </p>
              </div>
              <div className="image-side">
                <img className="side-image" src={lawyerImage} alt="Lawyer Image" />
                <div className="additional-content">
                  <h2 className="blue-heading">EDUCATION</h2>
                  <p className="blue-paragraph">Columbia Law School, J.D., 2008</p>
                  <p className="blue-paragraph">Duke University, B.A., 2005</p>
                </div>
                <div className="additional-content">
                  <h2 className="blue-heading">BAR ADMISSION</h2>
                  <p className="blue-paragraph">New York</p>
                </div>
                <div className="additional-content">
                  <h2 className="blue-heading">COURT ADMISSIONS</h2>
                  <p className="blue-paragraph">U.S. Court of Appeals, 2nd Circuit</p>
                  <p className="blue-paragraph">U.S. District Court, Southern District of New York</p>
                </div>
              </div>
            </div>
            <div className="manju">
              {/* First "steper" or accordion item */}
              <div className="steper">
                <h2 className="heading" onClick={toggleContent1}>
                  <span className={`plus-icon ${showContent1 ? 'rotate' : ''}`}>+</span> Speaking Engagements
                </h2>
                {showContent1 && (
                  <div className="accordion-content">
                    <p>
                      Employment and HR “Unconference” Lunch, TechGC, New York, NY, July 25, 2019
                    </p>
                    <p>
                      <h3>Where HR Meets Antitrust: The New Antitrust Risk From No-Poach and Wage-Fixing Agreements</h3>
                      Baker McKenzie, New York, NY, June 19, 2019
                    </p>
                  </div>
                )}
              </div>

              {/* Second "steper" or accordion item */}
              <div className="steper">
                <h2 className="heading" onClick={toggleContent2}>
                  <span className={`plus-icon ${showContent2 ? 'rotate' : ''}`}>+</span> Publications
                </h2>
                {showContent2 && (
                  <div className="accordion-content">
                    <p>
                      Content for Heading 2 goes here. This can include justified text or any other content you want to
                      display.
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Features;