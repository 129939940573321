import React from 'react';
import Contact from "../contact/Contact";
import Features from "../features/Features";
import Footer from "../footer/Footer";
import FooterBottom from "../footer/FooterBottom";
import EmployementAgreements from "../../pages/Employementarguments/EmployementAgreements";
import Counseling from "../../pages/counseling/Counseling";
import EqualPay from "../../pages/enqualpay/EqualPay";
import Litigation from "../../pages/litigation/Litigation";
import Sexvel from "../../pages/sexvelcases/Sexvel";
import Startups from "../../pages/startups/Startups";
import LeftBanner from './LeftBanner';
import RightBanner from './RightBanner';

const Banner = () => {
  return (
    <div>
      <section
id="home"
className="w-full pt-10 pb-20 flex flex-col gap-10 xl:gap-0 lgl:flex-row items-center border-b-[1px] font-titleFont border-b-black"
><LeftBanner />
<RightBanner />

</section>

       <Features />
      {/* <EmployementAgreements /> */}
      {/* <Counseling /> */}
      {/* <EqualPay /> */}
      {/* <Litigation /> */}
      {/* <Sexvel /> */}
      {/* <Startups /> */}
      <Contact />
      {/* <Footer /> */}
      {/* <FooterBottom />  */}

    </div>
   






  );
};

export default Banner;
