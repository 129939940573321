export const navLinksdata = [
  {
    _id: 1001,
    title: "Home",
    link: "home",
  },
  {
    _id: 1006,
    title: "Contact",
    link: "contact",
  },
  {
    _id: 1002,
    title: "About Us",
    link: "features",
  },
  {
    _id: 1004,
    title: "Call Us",
    link: "https://wa.me/919986715522?text=Hello,%20I%20would%20like%20to%20inquire%20about...",
    isWhatsAppLink: true,
  },
];
