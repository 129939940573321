// import React from 'react'
import React, { useState } from 'react';
import about from "../images/about.jpg";
import lawyerImage from "../images/law.jpg";

import '../counseling/counseling.css'

const Sexvel = () => {
    const [activeButton, setActiveButton] = useState('button1'); // State to track active button

    const handleButtonClick = (buttonName) => {
      setActiveButton(buttonName); // Update active button state
    };
  return (
    <div>
        <div className="app">
      <div className="image-container">
        <img
          className="background-image"
          src={about}
          alt="About"
        />
        <div className="content-container">
          {/* <div className="headings-container">
            <h2 className="heading">HOW WE HELP</h2>
            <h1 className="sub-heading">Executives & Employees</h1>
          </div> */}
          <div className="button-container">
              <h2 className='heading2'>HOW WE HELP</h2>
              <p className="Paragraph1">We represent executives and employees in all types of employment disputes and advisory matters.Some of our key practice areas are:</p>
              <p className="Paragraph1">Some of our key practice areas are:</p>
            {/* Buttons to toggle content */}
            <button className={activeButton === 'button1' ? 'active' : ''} onClick={() => handleButtonClick('button1')}>
             Sexual Harassment
            </button>
            <button className={activeButton === 'button2' ? 'active' : ''} onClick={() => handleButtonClick('button2')}>
             Equal Pay
            </button>
            <button className={activeButton === 'button3' ? 'active' : ''} onClick={() => handleButtonClick('button3')}>
             Litigation
            </button>
            <button className={activeButton === 'button4' ? 'active' : ''} onClick={() => handleButtonClick('button4')}>
            Agreements
            </button>
            <hr />
          </div>
          {/* Content based on active button */}
          <div className="text-container">
            {activeButton === 'button1' && (
              <>
                <div id='buttonnn1'>
                <img
                        id="side-image"
                         src={lawyerImage}
                         alt="Lawyer Image"
                     />
                     <div>
                     <h2 id='heading2'>Employment and Separation Agreements</h2>
                     <p id="Paragraph1">We understand the legal and business considerations behind entering into or exiting any employment relationship.  We have significant experience drafting and negotiating all types of employment agreements, including offer letters, employment contracts, restrictive covenants, and separation agreements.   We also have extensive experience litigating restrictive covenant and breach of contract claims.</p>
                     <p id='heading-p'>HOW WE HELP</p>
                     <p id="Paragraph1">We understand the legal and business considerations behind entering into or exiting any employment relationship.  We have significant experience drafting and negotiating all types of employment agreements, including offer letters, employment contracts, restrictive covenants, and separation agreements.   We also have extensive experience litigating restrictive covenant and breach of contract claims.</p>
                     </div>
                </div>
                
              </>
            )}
            {activeButton === 'button2' && (
              <>
              <div id='buttonnn2'>
                <img
                        id="side-image"
                         src={lawyerImage}
                         alt="Lawyer Image"
                     />
                <div>
                <h2 id='heading2'>Equal Pay</h2>
                <p id="Paragraph1">The gender pay gap and unlawful wage disparities persist across all industries and job levels.   Our firm is committed to fighting against pay discrimination based on sex, race or any other protected classification. </p>
                <p id='heading-p'>WHAT IS EQUAL PAY</p>
                <p id="Paragraph1">The Equal Pay Act requires equal pay for equal work for men and women.  New York law prohibits pay disparities based on sex and other protected characteristics, including age, race, creed/religion, color, national origin and sexual orientation, among others. 
                 Equal pay laws cover all forms of compensation: base salary, bonus, equity grants, commissions, benefits, and more.  Unlike other types of discrimination claims, employees do not have to prove a discriminatory motive to establish an equal pay claim.</p>
                 <p id='heading-p'>HOW WE HELP</p>
                 <p id="Paragraph1">We help employees and executives who are denied equal pay for equal or substantially similar work recover unpaid wages and other damages.  If you believe you may have an equal pay claim, please contact us.</p>
                </div>
                </div>
              </>
            )}
            {activeButton === 'button3' && (
              <>
                 <div id='buttonnn2'>
                <img
                        id="side-image"
                         src={lawyerImage}
                         alt="Lawyer Image"
                     />
                <div>
                <h2 id='heading2'>Employment Litigation</h2>
                <p id="Paragraph1">We are skilled litigators who have handled hundreds of employment disputes.  We represent individuals in all types of employment cases, including claims of:</p>
                <p id='heading-p'>OUR APPROACH FOR INDIVIDUALS </p>
                <p id="Paragraph1">Our management-side background makes us uniquely positioned to represent employees and executives.  After working exclusively for companies for over a decade, we have the management-side playbook down.  We understand how companies will approach the case and can anticipate their arguments and defenses.  </p>
                <p id="Paragraph1">Our management-side background makes us uniquely positioned to represent employees and executives.  After working exclusively for companies for over a decade, we have the management-side playbook down.  We understand how companies will approach the case and can anticipate their arguments and defenses.  </p>
                </div>
                </div>
              </>
            )}
            {activeButton === 'button4' && (
              <>
                <div id='buttonnn2'>
                <img
                        id="side-image"
                         src={lawyerImage}
                         alt="Lawyer Image"
                     />
                <div>
                <h2 id='heading2'>Employment and Separation Agreements</h2>
                <p id="Paragraph1">We understand the legal and business considerations behind entering into or exiting any employment relationship.We have significant experience drafting and negotiating all types of employment agreements, including offer letters, employment contracts, restrictive covenants, and separation agreements.We also have extensive experience litigating restrictive covenant and breach of contract claims.</p>
                <p id='heading-p'>HOW WE HELP</p>
                <p id="Paragraph1">We understand the legal and business considerations behind entering into or exiting any employment relationship.We have significant experience drafting and negotiating all types of employment agreements, including offer letters, employment contracts, restrictive covenants, and separation agreements.We also have extensive experience litigating restrictive covenant and breach of contract claims.</p>
                <p id="Paragraph1">We understand the legal and business considerations behind entering into or exiting any employment relationship.We have significant experience drafting and negotiating all types of employment agreements, including offer letters, employment contracts, restrictive covenants, and separation agreements.We also have extensive experience litigating restrictive covenant and breach of contract claims.</p>
                </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>




    </div>
  )
}

export default Sexvel