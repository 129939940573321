import React, { useState } from 'react';
import { FaFacebookF, FaTwitter, FaWhatsapp } from "react-icons/fa";
import {logo} from "../../assets/index"
const Footer = () => {
  const [showForm, setShowForm] = useState(false);

  const handleContactClick = () => {
    setShowForm(true);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission logic (e.g., send email)
    console.log('Form submitted:', e.target);
    // Reset form state or close form modal
    setShowForm(false);
  };

  return (
    <footer className="footer bg-dark text-white">
      <div className="container">
        <div className="grid grid-cols-1 md:grid-cols-2 justify-items-center md:justify-items-start gap-8">
          {/* Left section: Logo and social icons */}
          <div className="col-span-1 md:col-span-1">
            <div className="flex flex-col items-center md:items-start gap-8">
            <img src={logo} alt="logo" className="w-32 " />      
                    <div className="flex gap-4">
                <a href="#" className="text-white">
                  <FaFacebookF />
                </a>
                <a href="#" className="text-white">
                  <FaTwitter />
                </a>
                <a href="#" className="text-white">
                <FaWhatsapp />
                </a>
              </div>
            </div>
          </div>

          {/* Right section: Contact info, button, and legal text */}
          <div className="col-span-1 md:col-span-1">
            <div className="text-center md:text-left">
              <p>We're here to help.</p>
              <p>(000) 000-0000 | help@gmAIL.com</p>
              <button className="btn btn-primary" onClick={handleContactClick}>Contact Us</button>
              {showForm && (
                <form onSubmit={handleSubmit} className="mt-4">
                  <input type="text" name="name" placeholder="Your Name" className="form-control mb-2" required />
                  <input type="email" name="email" placeholder="Your Email" className="form-control mb-2" required />
                  <textarea name="message" placeholder="Your Message" className="form-control mb-2" rows="3" required />
                  <button type="submit" className="btn btn-primary">Submit</button>
                </form>
              )}
              <hr className="my-4" />
              <ul className="list-inline text-center md:text-left">
                <li className="list-inline-item">
                  <a href="#" className="text-white">
                    <i className="fa fa-envelope"></i>
                  </a>
                </li>
                <li className="list-inline-item">
                  <a href="#" className="text-white">
                    <i className="fa fa-linkedin"></i>
                  </a>
                </li>
                <li className="list-inline-item">
                  <a href="#" className="text-white">
                    <i className="fa fa-instagram"></i>
                  </a>
                </li>
                <li className="list-inline-item">
                  <a href="#" className="text-white">
                    <i className="fa fa-facebook"></i>
                  </a>
                </li>
              </ul>
              <p className="text-muted">
                Attorney Advertising | Terms of Use
                <br />
                ©2023 Kaufman Law Firm PLLC. All rights reserved. Site by Knapsack.
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;





